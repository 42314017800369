import React from 'react'
import "../Css/NotFound.css";

const NotFound = () => {
  return (
    <div className='not-found'>
      <h1>404 - Not Found</h1>
      <p>The page you are looking for does not exist.</p>
    </div>
  )
}

export default NotFound