import React from "react";
import classes from "./InfoModal.module.css";
import { useNavigate } from "react-router-dom";

const InfoModal = ({ infoModal, closeHandler }) => {
  const closeModal = () => {
    closeHandler();
  };
  return (
    <div
      className={`${
        infoModal
          ? `${classes.modal_container} ${classes.modal_show}`
          : classes.modal_container
      }`}
    >
      <div className={classes.modal_sub_container}>
        <i
          className={`fa-solid fa-xmark ${classes.close_btn}`}
          onClick={closeHandler}
        ></i>
        <div className={classes.modal_content}>
          <div className={classes.main_heading}>
            <p>Aby wejść do tej sekcji, musisz mieć ukończone 18 lat.</p>
          </div>
        </div>
        <div className={classes.btn_container}>
          <button className={classes.btn} onClick={closeModal}>
            mam ponad 18 lat
          </button>
          <button className={classes.btn_under} onClick={closeModal}>
            mam mniej niż 18 lat
          </button>
        </div>
      </div>
    </div>
  );
};

export default InfoModal;
